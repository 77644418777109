<template lang="pug">
.Modal.w-100
  .Modal-content.mx-4.px-3.py-2
    header(v-if="header").Modal-header.d-flex.align-items-center.justify-content-end.mb-4
      button(@click="goBack" :disabled="disabled").button-without-styles
        svg(width='17' height='16' viewbox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M16.48 16L10.624 7.808L16.224 0H11.488L8.256 4.512L5.024 0H0.256L5.856 7.84L0 16H4.768L8.256 11.136L11.712 16H16.48Z' fill='#788591')
    slot(name="body")
</template>

<script>
export default {
  name: "Modal",
  props: ["header", "goBack", "disabled"]
};
</script>

<style lang="scss">
.Modal {
  max-width: 360px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .Modal-header {}

.Modal-content {
  background-color: var(--color-white);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}
</style>
